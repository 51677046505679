@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

::-ms-input-placeholder {
  color: white;
}

.animation-container.pre-loading {
  display: none; /* یا هر استایل دیگری برای پنهان کردن آن در هنگام بارگذاری اولیه */
}


label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

a{
  color: #e7b40e;
}

p {
  display: flex;
  flex-direction: row;
  justify-content: start;
  font-size: 16px;
  font-weight: 400;
  color: white;
}

body, html {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  touch-action: none;
  -ms-touch-action: manipulation;
}

/* منطقه حساس به لمس در بالای صفحه */
.top-touch-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  z-index: 9999; /* برای اطمینان از اینکه در بالای سایر عناصر قرار دارد */
  touch-action: pan-down; /* اجازه کشیدن به سمت پایین */
}


.enable-tap {
  touch-action: manipulation;
}

.app {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  padding-top: 65px; /* فضایی برای هدر */
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
  box-sizing: border-box;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  font-size: 18px;
}

.icon1 {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  font-size: 18px;
}

.icon2 {
  width: 28px;
  height: 35px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.modal-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.level-display {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  color: white;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: -15px;
}

.level-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.35);
}

.progress-bar {
  width: 100%;
  max-width: 400px;
  height: 10px;
  background-color: #555;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #c9b208, #9e11dc);
  transition: width 0.5s ease;
}

.ncc-container {
  position: relative;
  height: 1800px;
  background-color: #222;
  border-radius: 30px;
  padding: 20px;
  margin-right: -8px;
  margin-left: -8px;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
}

.box-test1 {
  color: yellow;
  font-size: 14px;
}

.box-test2 {
  color: #2fe7a0;
  font-size: 14px;
}

.box-test3 {
  color: #ff588a;
  font-size: 14px;
}

.image-container22 {
  position: relative;
  display: inline-block;
}




/* Apply the spin animation to the background image */
.background-image {
  position: absolute;
  top: -30px;
  left: -30px;
  width: 92%;
  height: auto;
  z-index: 0;
}

.animation-container {
  width: 350px;  /* عرض مورد نظر برای انیمیشن */
  height: 320px; /* ارتفاع مورد نظر برای انیمیشن */
  margin: 0 auto; /* برای قرار دادن در مرکز صفحه */
}

@keyframes scale-up-down {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.scale-animation {
  animation: scale-up-down 0.2s ease-in-out;
}



.Army {
  width: 100%;
  height: auto;
  position: relative;
}

.Army-container {
  position: relative;
  cursor: pointer;
  width: 80%;
  height: auto;
  max-width: 400px;
  padding: 10px;
  box-sizing: border-box;
  margin: auto;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.hamster-container img {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.charge .icon, .boost .icon {
  width: 38px;
  height: 38px;
  margin-right: 10px;
}

.coin-display {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: white;
  font-size: 42px;
  font-weight: bold;
}

.coin-display .coin-icon {
  width: 64px;
  height: 64px;
  margin-right: 10px;
}

.info-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  background-color: #333;
  border-radius: 10px;
  color: white;
  border: 1px solid;
  border-image: linear-gradient(to top, #ffc400, rgba(0, 0, 0, 0.29)) 1;
}


.value {
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.charge, .boost {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
}

.click {
  position: fixed;
  font-size: 32px;
  font-weight: bold;
  color: #fc7f02;
  animation: moveUp 2.5s forwards;
}

@keyframes moveUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-150px);
  }
}


.modal-mulconf {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-mulconf-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 105%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
  animation: slideUp 0.5s ease-out forwards;
  height: 400px;
}


.modal-content {
  background-color: rgb(33, 33, 33);
  padding: 20px;
  border-radius: 20px 20px 0 0;
  box-sizing: border-box;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  margin-left: -15px;
  width: 100%;
}

.modal-content-label {
  margin-top: 30px;
}

.close {
  padding: 1px 4.5px 8px 4.5px;
  position: absolute;
  right: 25px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  background-color: rgba(131, 131, 131, 0.39);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.boost {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.boost:hover {
  background-color: #7e0aea;
}

.boost-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  transition: opacity 0.3s ease;
  overflow-y: scroll;
}

.boost-modal-content {
  background-color: #333;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  color: white;
  height: -webkit-fill-available;
}

.boost-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #444;
  border-radius: 25px;
  margin: auto;
  margin-bottom: 10px;
  width: 90%;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.boost-option:hover {
  background-color: #555;
}

.boost-option img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.boost-option span {
  flex-grow: 1;
}

.boost-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.boost-info img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.boost-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  font-size: 16px;
}

.multitap {
  margin-bottom: 5px;
}

.boost-price {
  font-size: 14px;
  color: rgba(203, 203, 203, 0.47);
}

.boost-option .boost-price {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: rgba(238, 238, 238, 0.45);
}

.boost-icons {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 12px;
}

.boost-icons img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.boosters-level {
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.boost-option .boost-price img {
  margin-right: 5px;
}

.label-class {
  margin-top: 50px;
  color: rgba(255, 255, 255, 0.4);
  font-family: Roboto;
  font-size: 14px;
}

.boost-titr {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 35px;
}

.boost-section {
  margin-bottom: 10px;
  margin-top: 60px;
}

.cooldown-timer {
  font-size: 14px;
  color: white;
  margin-left: 10px;
}

.cooldown-timer-modal {
  font-size: 14px;
  color: white;
  margin-top: 5px;
}

.boost-availability {
  font-size: 14px;
  color: white;
  margin-top: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.countdown-timer {
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  color: white;
  margin-left: 150px;
}

.confirm-modal {
  position: fixed;
  bottom: 0;
  width: 105%;
  margin-right: -8px;
  margin-left: -9px;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  border-radius: 30px;
  animation: slideInUp 0.3s ease-in-out;
  z-index: 3;
}

.confirm-modal-content {
  padding: 20px;
  text-align: center;
}

.confirm-modal-content img {
  margin-bottom: 0;
}

.confirm-modal-content h2 {
  margin-bottom: 10px;
}

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%; /* Adjust this value as needed */
  font-size: 16px;
}

.confirm-modal-content p {
  margin-bottom: 20px;
}

.confirm-button {
  white-space: nowrap;
  background-color: rgba(253, 197, 1, 0.92);
  color: white;
  border: none;
  padding: 25px 138px;
  border-radius: 15px;
  margin-bottom: 60px;
  margin-top: 25px;
  font-size: 20px;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;
}

.confirm-modal-content .close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.confirm-modal-content .close-modal:hover {
  color: #7e0aea;
}

.close-modal1 {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.modal-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  font-size: 18px;
  margin-bottom: 5px;
}

.energy-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.free-style {
  font-weight: 600;
  font-size: 28px;
  color: white;
}

.earn-modal {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Change align-items to flex-start to start from the top */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  transition: opacity 0.3s ease;
  overflow-y: auto;
  padding-bottom: 100px; /* Add padding-bottom for the bottom margin */
}

.earn-modal-content {
  background-color: #333;
  padding: 20px;
  border-radius: 0px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: white;
  overflow-y: auto;
  margin: 0 auto; /* Center the modal horizontally */
  max-height: calc(100vh - 90px);
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.coin-image {
  width: 500px;
  height: 300px;
  box-shadow: gold;
}

.modal-body {
  margin-top: -25px;
  text-align: left;
}

.mine-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -25px;
  text-align: left;
  overflow-y: scroll;
}



.task-item-youtube {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #444;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  animation: heartbeat 2.5s infinite;
}

@keyframes heartbeat {
  0%, 28%, 70%, 100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 0, 0, 0);
  }
  14%, 42% {
    transform: scale(0.75);
    box-shadow: 0 0 20px rgba(213, 30, 30, 0.53);
  }
}

.task-item-invitefriends{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #444;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 50px;
  animation: heartbeat2 2.5s infinite;
}

@keyframes heartbeat2 {
  0%, 28%, 70%, 100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 0, 0, 0);
  }
  14%, 42% {
    transform: scale(0.75);
    box-shadow:0 0 20px rgb(255, 196, 0);
  }
}


.task-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #444;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.task-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.task-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.task-title {
  font-size: 16px;
  font-weight: bold;
}

.task-reward {
  font-size: 15px;
  color: #eccc39;
  font-weight: 700;
}

.task-status {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #00cc97;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .click {
    font-size: 28px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .header-left, .header-right {
    margin: 0 10px;
  }
  .earn-modal-content {
    padding: 15px;
  }
  .task-item {
    padding: 8px;
  }
  .task-icon {
    width: 40px;
    height: 40px;
  }
  .task-title {
    font-size: 14px;
  }
  .task-reward {
    font-size: 12px;
  }
  .task-status {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .click {
    font-size: 24px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .header-left, .header-right {
    margin: 0 5px;
  }
  .earn-modal-content {
    padding: 15px;
  }
  .task-item {
    padding: 8px;
  }
  .task-icon {
    width: 35px;
    height: 35px;
  }
  .task-title {
    font-size: 14px;
  }
  .task-reward {
    font-size: 15px;
  }
  .task-status {
    font-size: 26px;
  }
}

.telegram-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 101%;
  margin-left: -5px;
  margin-bottom: 35px;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  border-radius: 30px;
  animation: slideInUp 0.3s ease-in-out;
  z-index: 3;
}

.telegram-modal-content {
  background-color: rgba(33, 33, 33, 0.7);
  padding: 20px;
  border-radius: 35px 35px 0 0;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.telegram-icon {
  display: flex;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin: auto;
}

.join-button {
  display: flex;
  justify-content: center;
  width: 30%;
  padding: 15px;
  margin: auto;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #4a90e2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.check-button {
  display: block;
  width: 100%;
  padding: 20px;
  margin: 10px 0 0 0;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #4a90e2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.join-button:hover, .check-button:hover {
  background-color: #357ab8;
}

.reward-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.reward-icon {
  display: block;
  margin: 3px auto;
  width: 30px;
  height: 30px;
}

.join-tg-group {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.join-tg-group2 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.join-instagram-group {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.join-x-group {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.telegram-price {
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-left: 10px;
}

.telegram-modal-coin {
  width: 50px;
  height: 50px;
}

.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.error-modal-content {
  background-color: #252525;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #ffffff;
  width: 80%;
  border: 2px solid #e7b40e;
  box-shadow: 1px 1px 10px #e7b40e;
}

.error-text {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.error-ok-button {
  width: 80%;
}

.error-modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.error-modal-content button:hover {
  background-color: #0056b3;
}

.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.success-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #222222;
}

.success-modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.success-modal-content button:hover {
  background-color: #0056b3;
}

.telegram-modal2 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 101%;
  margin-left: -5px;
  margin-bottom: 35px;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  border-radius: 30px;
  animation: slideInUp 0.3s ease-in-out;
  z-index: 3;
}

.telegram-modal-content2 {
  background-color: rgba(33, 33, 33, 0.7);
  padding: 20px;
  border-radius: 35px 35px 0 0;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
}

.telegram-icon2 {
  display: flex;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin: auto;
}

.close-modal2 {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.join-button2 {
  display: flex;
  justify-content: center;
  width: 30%;
  padding: 15px;
  margin: auto;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #4a90e2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.join-button2:hover {
  background-color: #357ab8;
}

.reward-info2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.reward-icon2 {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.telegram-price2 {
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-left: 10px;
}

.telegram-modal-coin2 {
  width: 50px;
  height: 50px;
}

.instagram-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 101%;
  margin-left: -5px;
  margin-bottom: 35px;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  border-radius: 30px;
  animation: slideInUp 0.3s ease-in-out;
  z-index: 3;
}

.instagram-modal-content {
  background-color: rgba(33, 33, 33, 0.7);
  padding: 20px;
  border-radius: 35px 35px 0 0;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.instagram-icon {
  display: flex;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin: auto;
}

.instagram-price {
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-left: 10px;
}

.instagram-modal-coin {
  width: 50px;
  height: 50px;
}

.youtube-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 101%;
  margin-left: -5px;
  margin-bottom: 35px;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  border-radius: 30px;
  animation: slideInUp 0.3s ease-in-out;
  z-index: 3;
}

.youtube-modal-content {
  background-color: rgba(33, 33, 33, 0.7);
  padding: 20px;
  border-radius: 35px 35px 0 0;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.youtube-icon {
  display: flex;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin: auto;
}

.youtube-price {
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-left: 10px;
}

.instagram-modal-coin {
  width: 50px;
  height: 50px;
}

.x-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 101%;
  margin-left: -5px;
  margin-bottom: 35px;
  background-color: #1f1f1f;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  border-radius: 30px;
  animation: slideInUp 0.3s ease-in-out;
  z-index: 5;
}

.x-modal-content {
  background-color: rgba(33, 33, 33, 0.7);
  padding: 20px;
  border-radius: 35px 35px 0 0;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.x-icon {
  display: flex;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  margin: auto;
}

.x-price {
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-left: 10px;
}

.x-modal-coin {
  width: 50px;
  height: 50px;
}

.daily-reward-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.reward-item.claimed {
  border: none;
  background: linear-gradient(to top, #00cc97, #009e73);
  color: white;
}

.daily-reward-modal-content {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  color: white;
  position: relative;
}

.daily-reward-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.reward-item {
  background-color: #444;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reward-item.inactive {
  opacity: 0.5;
}

.reward-item.active {
  border: 2px solid #00cc97;
}

.claim-button {
  background-color: #4a90e2;
  color: white;
  padding: 20px;
  width: 100%;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.claim-button:hover {
  background-color: #357ab8;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 10000;
}

.mine-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.settings-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  transition: opacity 0.3s ease;
}

.settings-modal-content {
  background-color: #333;
  padding: 20px;
  border-radius: 1px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: white;
  height: -webkit-fill-available;
}

.settings-modal .close-modal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.settings-modal .modal-body1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-button {
  display: block;
  align-items: center;
  background-color: #ccb81c;
  color: white;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto;
  width: 90%;
}

.settings-user-info-body {
  background-color: rgba(255, 215, 0, 0.1);
  width: 100%;
  border-radius: 16px;
  height: auto;
  overflow-y: auto;
}

.slider {
  width: 80%;
  margin: 2px 10px 20px 10px;
}

.slider-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  padding: 10px 10px;
}

.wallet-input {
  border: none;
  border-bottom: 1px solid #ffc200;
  box-shadow: 0px 0px 5px rgb(255, 153, 0);
  width: 90%;
  padding: 10px;
  margin: 1px 0;
  box-sizing: border-box;
  text-align: left;
  background-color: transparent;
  color: white;
}

.wallet-input:focus {
  border: none;
  color: #ffc200;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  width: 100%;
}

.tabs button {
  flex: 1;
  padding: 10px 20px;
  border: none;
  background-color: #444;
  color: white;
  cursor: pointer;
  text-align: center;
  margin: 0 5px;
}

.tabs button.active {
  background-color: rgb(234, 209, 8);
  color: #222222;
}

.tab-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  overflow-y: auto;
  height: calc(100vh - 100px);
}

.alert-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-modal-content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 300px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.alert-button {
  background-color: #e202fc;
  color: white;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.footer {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  box-sizing: border-box;
  flex-direction: column;
  width: 300px;
}

.footer span {
  text-align: center;
}

.inv-fri-head {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.friend-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}

.friend-modal-content {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: white;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  margin-bottom: 100px;
}

.mine-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}

.mine-modal-content {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  color: white;
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  margin-bottom: 100px;
  margin-top: 30px;
}




.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.tabs button.active {
  color: blue;
  border-bottom: 2px solid blue;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
}

.cards-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.card {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-content {
  text-align: center;
}

.card-info {
  margin-top: 5px;
}

.card-info.locked {
  color: red;
  font-weight: bold;
}

.start-mining {
  background-color: #e7b40e;
  margin-top: 80px;
  padding: 5px 100px;
  border-radius: 8px;
  font-size: 29px;
}

.bonus-item {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
}

.bonus-icon {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.bonus-text {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 16px;
  padding: 15px;
}

.bonus-text p {
  margin: 0;
  line-height: 1.2;
}

.bonus-text span {
  font-weight: bold;
  color: #FFD700;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #333;
  border-top: 1px solid #444;
}

.invite-button {
  flex: 5;
  width: 70%;
  margin: 0 5px;
  padding: 15px;
  font-size: 16px;
  color: white;
  background-color: #4a90e2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.other-button {
  flex: 1;
  width: 30%;
  margin: 0 5px;
  padding: 15px;
  font-size: 16px;
  color: white;
  background-color: #4a90e2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.invite-button:hover, .other-button:hover {
  background-color: #357ab8;
}

.friend-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.friend-list-container {
  text-align: left;
  margin-top: 20px;
}

.friend-list-header {
  margin-bottom: 10px;
}

.no-friends-text {
  background-color: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.5);
  padding: 30px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: auto;
  align-items: center;
  font-weight: 700;
}

.friend-table td {
  border: none;
  padding: 15px 10px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.friend-table .friend-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #2b2b2b;
  border-radius: 10px;
}

.friend-table .friend-row img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.friend-table .friend-row .friend-info {
  display: flex;
  align-items: center;
}

.friend-table .friend-row .friend-info span {
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
}

.friend-table .friend-row .friend-rewards {
  display: flex;
  align-items: center;
}

.friend-table .friend-row .friend-rewards span {
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
}

.friend-table .friend-row .friend-rewards img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.show-table-button {
  background-color: transparent;
  font-size: 24px;
  font-weight: 700;
  color: #e7b40e;
  border: none;
  margin-top: 15px;
}

.invited-users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  max-height: calc(100vh - 90px);
  padding-bottom: 100px; /* Add padding-bottom for the bottom margin */
    margin-bottom: 50px;
}

.invited-users-table th {
  background-color: #444;
  color: white;
  padding: 10px;
  text-align: center;
  max-height: calc(100vh - 90px);
}

.invited-users-table td {
  border-bottom: 1px solid rgb(229, 179, 14);
  margin: 0;
  background-color: rgba(255, 234, 0, 0.05);
  color: white;
  padding: 10px;
  text-align: center;
  max-height: calc(100vh - 90px);
}

.invited-users-table tr:last-child td {
  border-bottom: none; /* برای حذف خط زیر آخرین ردیف */
}

.coin-display {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coin-icon {
  margin-right: 10px;
  padding-bottom: 2px;
}

.image-container2 {
  margin-top: 20px;
  text-align: center;
}

.image-container2 img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 300px;
}

.download-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
}

.download-link:hover {
  background-color: #45a049;
}

.image-container {
  margin-top: 20px;
}

.generated-image {
  max-width: 80%;
  height: auto;
  border: 2px solid #fff;
  border-radius: 10px;
  margin-top: 20px;
}

.dail-combo-text {
  background-color: #e7b40e;
  margin-top: 80px;
  padding: 5px 100px;
  border-radius: 8px;
}

.daily-influence {}

.dail-combo-image {
  display: flex;
  margin: auto;
  cursor: pointer;
}

.daily-combo-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.16);
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
  width: 90%;
  max-width: 600px;
  margin-bottom: 10px;
}

.daily-combo-dots {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #555;
  border-radius: 50%;
}

.daily-combo-reward {
  display: flex;
  align-items: center;
  background-color: #494023;
  padding: 5px 10px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.coin-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

#hashtag {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  -webkit-tap-highlight-color: transparent;
}

.hshtag-btn {
  margin-top: 10px;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  color: white;
  text-align: center;
}

.qr-container img {
  width: 200px;
  height: 200px;
}

.qr-container p {
  margin-top: 20px;
  font-size: 24px;
}

.refresh {
  display: flex;
  justify-content: space-between;
  color: #e7b40e;
}

.refresh-btn {
  background-color: transparent;
  border: none;
  padding: 0 10px;
}

.refresh-btn:hover {
  background-color: #e7b40e;
}

.coming-soon {
  width: 110%;
  height: 870px;
  margin-left: -20px;
  margin-top: -15px;
}

.mobile-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #333;
  padding: 5px 0 20px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 14px;
  position: relative
}

.menu-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.daily-reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #222;
  color: white;
}

.daily-reward img {
  width: 200px;
  height: 200px;
}

.daily-reward p {
  margin-top: 20px;
  font-size: 24px;
}

.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.success-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #222222;
}

.success-modal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.success-modal-content button:hover {
  background-color: #0056b3;
}

.task-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
}

.task-list .task-item {
  background-color: #444;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
}

.task-list .task-item h3 {
  margin: 0;
  font-size: 18px;
}

.task-list .task-item p {
  margin: 5px 0 0;
  font-size: 14px;
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222;
  color: white;
  padding: 20px;
}

.settings input {
  background-color: #444;
  border: none;
  padding: 10px;
  margin: 10px 0;
  color: white;
  border-radius: 5px;
}

.settings button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.settings button:hover {
  background-color: #0056b3;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .header-left, .header-right {
    margin: 0 10px;
  }
  .click {
    font-size: 28px;
  }
  .earn-modal-content {
    padding: 15px;
  }
  .task-item {
    padding: 8px;
  }
  .task-icon {
    width: 40px;
    height: 40px;
  }
  .task-title {
    font-size: 14px;
  }
  .task-reward {
    font-size: 12px;
  }
  .task-status {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .header {
    flex-direction: row;
    justify-content: space-between;
  }
  .header-left, .header-right {
    margin: 0 5px;
  }
  .click {
    font-size: 24px;
  }
  .earn-modal-content {
    padding: 15px;
  }
  .task-item {
    padding: 8px;
  }
  .task-icon {
    width: 35px;
    height: 35px;
  }
  .task-title {
    font-size: 14px;
  }
  .task-reward {
    font-size: 15px;
  }
  .task-status {
    font-size: 26px;
  }
}

/* Preloader styles */
.preloader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  background-image: url('./images/L&A.jpg'); /* مسیر به تصویر پس‌زمینه */
  background-size: cover; /* برای پوشش کامل پس‌زمینه */
  background-position: center; /* مرکز قرارگیری تصویر */
  z-index: 11000;
}

.preloader-content{
  display: flex;
  flex-direction: column;
}

.pre-con-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  text-shadow: #222222;
}

.pre-con-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #e7b40e;
  text-shadow: #222222;
  justify-content: center;
  align-items: center;
}

.pre-con-text1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 56px;
  font-weight: 700;
  color: #ffffff;
  text-shadow: #222222;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 250px;
  width: 250px;
  height: 200px;
  animation: fade 1s linear infinite;
}

@keyframes fade {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.social-icons img {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.social-icons img:hover {
  transform: scale(1.2);
}

.qr-style {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  margin: 100px 0 0 0;
}

.register-button {
  display: flex;
  justify-content: center;
  margin-top: -50px;
  margin-bottom: 45px;
  padding: 5px 20px;
  background-color: #e7bc23;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #8c0de7;
}

@keyframes coinRain {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

.coin {
  position: absolute;
  top: -50px;
  width: 30px;
  height: 30px;
  animation: coinRain 1.5s linear infinite;
  pointer-events: none;
  z-index: 1000;
}

.coin:nth-child(odd) {
  animation-duration: 2s;
}

.coin:nth-child(even) {
  animation-duration: 1.5s;
}

.boost-text-header {
  margin-left: 15px;
  font-size: 24px;
}

.invited-users-container {
  z-index: 1000;
  max-height: 370px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}


.earn-modal-top-image{
  padding-top: 10px;
  width: 180px;
  height: 120px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* پس زمینه تاریک */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}


/*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*/
/*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*/

.modal-content1 {
  background-color: rgba(0, 0, 0, 0.46);
  padding: 20px;
  border-radius: 10px;
  left: 7px;
  max-width: 500px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0px 0px 20px rgb(255, 153, 0);
  border: 3px solid rgba(253, 197, 1, 0.92);
  margin-left: -15px;
  width: 100%;
  top: -20px;
}

.wheel-container {
  position: relative;
  width: 300px;
  height: 300px;
  left: 30px;
  margin-bottom: 20px;
}

.wheel {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #e7b40e;
  position: relative;
  transition: transform 5s ease-out;
  overflow: hidden;
}

.prize {
  position: absolute;
  width: 100%;
  top: 30%;
  left: 0;
  transform-origin: center;
  background-color: rgba(255, 255, 255, 0.11);
  text-align: left;
  line-height: 120px;
  clip-path: polygon(50% 50%, 100% 0%, 100% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: white;
}

.prize:nth-child(1) {
  transform: rotate(0deg);
  background: linear-gradient(45deg, #e0e0e0, #282828, #ffffff, #3f3f3f);
}

.prize:nth-child(2) {
  transform: rotate(40deg);
  background: linear-gradient(45deg, #ff7f50, #480f04, #ff7f50, #480f04);
}

.prize:nth-child(3) {
  transform: rotate(80deg);
  background: linear-gradient(45deg, #ffa500, #422602, #ffa500, #422602);
}

.prize:nth-child(4) {
  transform: rotate(120deg);
  background: linear-gradient(45deg, #f700ff, #400342, #f700ff, #400342);
}

.prize:nth-child(5) {
  transform: rotate(160deg);
  background: linear-gradient(45deg, #ff0000, #500000, #ff0000, #500000);
}

.prize:nth-child(6) {
  transform: rotate(200deg);
  background: linear-gradient(45deg, #32cd32, #013f01, #32cd32, #013f01);
}

.prize:nth-child(7) {
  transform: rotate(240deg);
  background: linear-gradient(45deg, #ffd000, #463d07, #ffe000, #463d07);
}

.prize:nth-child(8) {
  transform: rotate(280deg);
  background: linear-gradient(45deg, #00ced1, #03423e, #00ced1, #03423e);
}

.prize:nth-child(9) {
  transform: rotate(320deg);
  background: linear-gradient(45deg, #008aff, #011241, #008aff, #011241);
}


.label1 {
  display: inline-block;
  font-size: 24px;
  color: white;
  font-weight: bold;
  position: relative;
  top: 0;
  left: 100px;
  width: 100%;
  text-align: center;
  text-shadow: 2px 0px 4px rgba(0, 0, 0, 1);
}
.label2 {
  font-size: 24px;
  color: white;
  font-weight: bold;

}

.Congratulations {
  display: block;
  margin-top: 0;
}

.wheel-center {
  position: absolute;
  top: 51%;
  left: 52%;
  width: 60px;
  height: 60px;
  background-color: black;
  color: white;
  border-radius: 50%;
  line-height: 60px;
  text-align: center;
  transform: translate(-50%, -50%);
  font-size: 16px;
  z-index: 10;
}

.wheel-center::after {
  content: "";
  position: absolute;
  top: -10px; /* تنظیم موقعیت فلش */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid black; /* رنگ فلش */
}


.spin-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #FFD700; /* رنگ طلایی */
  color: black;
  border: 2px solid #FFD700;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.spin-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.6), transparent);
  transition: all 0.3s ease;
  z-index: 1;
}

.spin-button:hover {
  background-color: #FFC107; /* تغییر رنگ پس از هاور */
}

.spin-button::before {
  animation: shine 1s infinite linear;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.spin-button:disabled {
  background-color: #FFD700;
  cursor: not-allowed;
  opacity: 0.6;
}



.spinning {
  transition: transform 5s ease-out;
}

.golden-glow {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  color: #FFD700;
  position: relative;
  overflow: hidden;
}

.golden-glow::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgb(255, 204, 0), transparent);
  transition: all 0.3s ease;
  z-index: 1;
}

.golden-glow::before {
  animation: shine 1.5s infinite linear;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}


.prize-amount {
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: gold;
}


@keyframes fire {
  0% { transform: scale(1); filter: brightness(1); }
  50% { transform: scale(1.1); filter: brightness(1.2); }
  100% { transform: scale(1); filter: brightness(1); }
}

.fire-effect {
  animation: fire 1s infinite;
  color: #ff4500; /* رنگ آتشین */
  text-shadow: 0 0 10px #ff4500, 0 0 20px #ffae00, 0 0 30px #ff4500;
}




.oswald-countdown {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500; /* می‌توانید از 200 تا 700 انتخاب کنید */
  font-style: normal;
}



.countdown-timer-turbo {
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: rgba(255, 166, 0, 0.15);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 330px;
  font-weight: 900;
  font-family: "Oswald", sans-serif;
  z-index: 0; /* پشت تمام المان‌های دیگر */
  pointer-events: none; /* از تداخل با کلیک‌های کاربر جلوگیری می‌کند */
}

@keyframes fly {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* حرکت به سمت بالا */
  }
  100% {
    transform: translateY(0); /* بازگشت به موقعیت اصلی */
  }
}

.fly-animation {
  animation: fly 2s ease-in-out infinite; /* زمان انیمیشن 2 ثانیه با تکرار بی‌نهایت */
}

.claw-mark {
  margin-top: -90px;
  width: 110px;
  height: 110px;
  pointer-events: none; /* جلوگیری از تداخل با کلیک‌های دیگر */
  transform: translate(-50%, -50%); /* برای قرار دادن مرکز تصویر در موقعیت کلیک */
  transition: opacity 1s ease-in-out; /* تنظیم برای محو شدن تدریجی */
}

.claw-mark.fade-out {
  opacity: 0; /* محو شدن تدریجی */
}

/*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*/
/*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*//*spin gane*/



@media (max-height: 700px) {
  .wheel,
  .label,
  .prize {
    transform: scale(0.7);
    transform-origin: center;
  }

  .wheel-container
   {
    transform: scale(0.7);
    transform-origin: center;
    top: -35px;
  }

  .wheel-center{
    transform: scale(0.7);
    transform-origin: center;
    top: 42%;
    left: 42%;
    width: 60px;
    height: 60px;
  }
  .Congratulations {
    display: block;
    margin-top: -70px;
  }







  .countdown-timer-turbo {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    color: rgba(255, 166, 0, 0.15);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 300px;
    font-weight: 900;
    font-family: "Oswald", sans-serif;
    z-index: 0; /* پشت تمام المان‌های دیگر */
    pointer-events: none; /* از تداخل با کلیک‌های کاربر جلوگیری می‌کند */
  }






  .earn-modal-top-image {
    width: 140px;
    height: 100px;
  }

  .invited-users-container {
    max-height: 220px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .boost-text-header {
    margin-left: 15px;
    font-size: 20px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
  .header-left, .header-right {
    margin: 0 5px;
  }
  .icon, .icon2 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    font-size: 14px;
  }
  .icon1{
    margin-right: 5px;
  }
  .modal-button {
    padding: 5px;
    font-size: 14px;
  }
  .level-display {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .progress-bar, .progress {
    height: 8px;
  }
  .ncc-container {
    width: 95%;
    height: 1200px;
    padding: 10px;
  }
  .box-test1, .box-test2, .box-test3 {
    font-size: 12px;
  }
  .Army-container {
    width: 88%;
  }
  .Army{
    width: 270px;
    padding-left: 10px;
  }
  .hamster-container img {
    width: 50px;
    height: 50px;
  }
  .charge .icon, .boost .icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .coin-display {
    font-size: 36px;
    margin: 10px 0;
  }
  .coin-display .coin-icon {
    width: 48px;
    height: 48px;
    margin-right: 5px;
  }
  .info-container, .info-box {
    padding: 5px 15px;
    font-size: 14px;
  }
  .value {
    font-size: 12px;
  }
  .actions-container {
    padding: 5px;
  }
  .charge, .boost {
    font-size: 14px;
    padding: 5px;
    margin-right: 20px;
    margin-left: 12px;
  }
  .click {
    font-size: 24px;
  }
  .modal {
    height: 300px;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-content-label {
    margin-top: 20px;
  }
  .close {
    width: 24px;
    height: 24px;
  }
  .boost-modal {
    padding: 10px;
  }
  .boost-modal-content {
    padding: 10px;
    margin-left: -20px;
    margin-top: -10px;
  }
  .boost-option {
    padding: 1px;
    font-size: 12px;
  }
  .boost-option img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .boost-option span {
    font-size: 12px;
  }
  .boost-price {
    font-size: 12px;
  }
  .boost-info {
    padding: 5px;
  }
  .boost-info img {
    width: 20px;
    height: 20px;
  }
  .boost-text {
    font-size: 14px;
  }
  .cooldown-timer, .cooldown-timer-modal, .boost-availability {
    font-size: 12px;
  }
  .countdown-timer {
    font-size: 12px;
    margin-left: 10px;
  }
  .confirm-modal {
    padding: 10px;
  }
  .confirm-modal-content {
    padding: 10px;
  }

  .close-modal1 {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }
  .energy-modal {
    padding: 10px;
  }
  .free-style {
    font-size: 24px;
  }
  .task-icon{
    width: 20px;
    height: 20px;
  }
  .task-daily-list {
    margin: 5px;
    padding: 2px;
  }

  .task-item, .task-info, .task-title, .task-reward, .task-status {
    font-size: 12px;

  }
  .telegram-modal, .telegram-modal-content, .telegram-icon, .join-button, .check-button {
    font-size: 16px;
  }
  .reward-info, .reward-icon {
    padding: 5px;
    font-size: 12px;
  }
  .join-tg-group, .join-tg-group2, .join-instagram-group, .join-x-group {
    padding: 5px;
    font-size: 24px;
  }
  .telegram-price, .telegram-modal-coin {
    font-size: 24px;
    margin-left: 5px;
  }
  .error-modal, .error-modal-content {
    padding: 10px;
  }
  .error-text {
    font-size: 16px;
  }
  .error-ok-button {
    padding: 10px;
    font-size: 16px;
  }
  .success-modal, .success-modal-content {
    padding: 10px;
  }
  .success-modal-content button {
    padding: 10px;
    font-size: 16px;
  }
  .telegram-modal2, .telegram-modal-content2, .telegram-icon2, .join-button2, .reward-info2, .reward-icon2, .telegram-price2, .telegram-modal-coin2 {
    font-size: 24px;
  }
  .instagram-modal, .instagram-modal-content, .instagram-icon, .instagram-price, .instagram-modal-coin {
    font-size: 24px;
  }
  .x-modal, .x-modal-content, .x-icon, .x-price, .x-modal-coin {
    font-size: 24px;
  }
  .daily-reward-modal, .daily-reward-modal-content, .daily-reward-grid, .reward-item, .claim-button, .image-container, .mine-image, .wallet-input, .tabs, .tab-content, .alert-modal, .alert-modal-content, .alert-button, .friend-modal, .friend-modal-content, .bonus-item, .modal-buttons, .invite-button, .other-button, .friend-table, .friend-list-container, .friend-list-header, .no-friends-text, .friend-table td, .friend-table .friend-row, .friend-table .friend-row img, .friend-table .friend-row .friend-info, .friend-table .friend-row .friend-info span, .friend-table .friend-row .friend-rewards, .friend-table .friend-row .friend-rewards span, .friend-table .friend-row .friend-rewards img, .show-table-button, .invited-users-table, .coin-icon, .image-container2, .image-container2 img, .download-link, .image-container, .generated-image, .dail-combo-text, .daily-influence, .dail-combo-image, .daily-combo-box, .daily-combo-dots, .dot, .daily-combo-reward, #hashtag, .qr-container, .qr-container img, .qr-container p, .refresh, .refresh-btn, .menu-item, .menu-icon, .daily-reward, .task-list, .task-list .task-item, .task-list .task-item h3, .task-list .task-item p, .settings, .settings input, .preloader, .preloader-content, .pre-con-header, .pre-con-text, .pre-con-text1, .loader, .social-icons, .social-icons img, .coin {
    font-size: 12px;
    margin-top: 2px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-menu {
    padding: 5px 0;
  }

  .footer{
    padding: 0;
  }
  .coming-soon {
    width: 400px;
    height: auto;
  }
  .register-button{
    padding: 1.2rem 1rem;
    font-size: 16px;
    width: 90%;
  }
  .qr-style{
    margin-bottom: -30px;
  }
  .coin-display{
    font-size: 38px;
  }
  .earn-modal-content {
    height: 100%;
    max-height: calc(100vh - 70px);

  }

  .slider-text {
    font-size: 12px;
  }
}





@media (max-height: 660px) {
  .earn-modal-top-image {
    width: 140px;
    height: 100px;
  }

  .invited-users-container {
    max-height: 220px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .boost-text-header {
    margin-left: 15px;
    font-size: 20px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
  .header-left, .header-right {
    margin: 0 5px;
  }
  .icon, .icon2 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    font-size: 14px;
  }
  .icon1{
    margin-right: 5px;
  }
  .modal-button {
    padding: 5px;
    font-size: 14px;
  }
  .level-display {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .progress-bar, .progress {
    height: 8px;
  }
  .ncc-container {
    height: 1200px;
    padding: 10px;
  }
  .box-test1, .box-test2, .box-test3 {
    font-size: 12px;
  }
  .Army-container {
    width: 92%;
  }
  .Army{
    width: 250px;
    height: auto;
    display: flex;
    justify-content: center;
  }
  .hamster-container img {
    width: 50px;
    height: 50px;
  }
  .charge .icon, .boost .icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .coin-display {
    font-size: 36px;
    margin: 10px 0;
  }
  .coin-display .coin-icon {
    width: 48px;
    height: 48px;
    margin-right: 5px;
  }
  .info-container, .info-box {
    padding: 5px 8px;
    font-size: 14px;
  }
  .value {
    font-size: 12px;
  }
  .actions-container {
    padding: 5px;
  }
  .charge, .boost {
    font-size: 14px;
    padding: 5px;
    margin-right: 20px;
    margin-left: 12px;
  }
  .click {
    font-size: 24px;
  }
  .modal {
    height: 300px;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-content-label {
    margin-top: 20px;
  }
  .close {
    width: 24px;
    height: 24px;
  }
  .boost-modal {
    padding: 10px;
  }
  .boost-modal-content {
    padding: 10px;
    margin-left: -20px;
    margin-top: -10px;
  }
  .boost-option {
    padding: 1px;
    font-size: 12px;
  }
  .boost-option img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .boost-option span {
    font-size: 12px;
  }
  .boost-price {
    font-size: 12px;
  }
  .boost-info {
    padding: 5px;
  }
  .boost-info img {
    width: 20px;
    height: 20px;
  }
  .boost-text {
    font-size: 14px;
  }
  .cooldown-timer, .cooldown-timer-modal, .boost-availability {
    font-size: 12px;
  }
  .countdown-timer {
    font-size: 12px;
    margin-left: 10px;
  }
  .confirm-modal {
    padding: 10px;
  }
  .confirm-modal-content {
    padding: 10px;
  }

  .close-modal1 {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }
  .energy-modal {
    padding: 10px;
  }
  .free-style {
    font-size: 24px;
  }
  .task-icon{
    width: 20px;
    height: 20px;
  }
  .task-daily-list {
    margin: 5px;
    padding: 2px;
  }

  .task-item, .task-info, .task-title, .task-reward, .task-status {
    font-size: 12px;

  }
  .telegram-modal, .telegram-modal-content, .telegram-icon, .join-button, .check-button {
    font-size: 16px;
  }
  .reward-info, .reward-icon {
    padding: 5px;
    font-size: 12px;
  }
  .join-tg-group, .join-tg-group2, .join-instagram-group, .join-x-group {
    padding: 5px;
    font-size: 24px;
  }
  .telegram-price, .telegram-modal-coin {
    font-size: 24px;
    margin-left: 5px;
  }
  .error-modal, .error-modal-content {
    padding: 10px;
  }
  .error-text {
    font-size: 16px;
  }
  .error-ok-button {
    padding: 10px;
    font-size: 16px;
  }
  .success-modal, .success-modal-content {
    padding: 10px;
  }
  .success-modal-content button {
    padding: 10px;
    font-size: 16px;
  }
  .telegram-modal2, .telegram-modal-content2, .telegram-icon2, .join-button2, .reward-info2, .reward-icon2, .telegram-price2, .telegram-modal-coin2 {
    font-size: 24px;
  }
  .instagram-modal, .instagram-modal-content, .instagram-icon, .instagram-price, .instagram-modal-coin {
    font-size: 24px;
  }
  .x-modal, .x-modal-content, .x-icon, .x-price, .x-modal-coin {
    font-size: 24px;
  }
  .daily-reward-modal, .daily-reward-modal-content, .daily-reward-grid, .reward-item, .claim-button, .image-container, .mine-image, .wallet-input, .tabs, .tab-content, .alert-modal, .alert-modal-content, .alert-button, .friend-modal, .friend-modal-content, .bonus-item, .modal-buttons, .invite-button, .other-button, .friend-table, .friend-list-container, .friend-list-header, .no-friends-text, .friend-table td, .friend-table .friend-row, .friend-table .friend-row img, .friend-table .friend-row .friend-info, .friend-table .friend-row .friend-info span, .friend-table .friend-row .friend-rewards, .friend-table .friend-row .friend-rewards span, .friend-table .friend-row .friend-rewards img, .show-table-button, .invited-users-table, .coin-icon, .image-container2, .image-container2 img, .download-link, .image-container, .generated-image, .dail-combo-text, .daily-influence, .dail-combo-image, .daily-combo-box, .daily-combo-dots, .dot, .daily-combo-reward, #hashtag, .qr-container, .qr-container img, .qr-container p, .refresh, .refresh-btn, .menu-item, .menu-icon, .daily-reward, .task-list, .task-list .task-item, .task-list .task-item h3, .task-list .task-item p, .settings, .settings input, .preloader, .preloader-content, .pre-con-header, .pre-con-text, .pre-con-text1, .loader, .social-icons, .social-icons img, .coin {
    font-size: 12px;
    margin-top: 2px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-menu {
    padding: 5px 0;
  }
  .footer{
    padding: 0;
  }
  .coming-soon {
    width: 400px;
    height: auto;
  }
  .register-button{
    padding: 1.2rem 1rem;
    font-size: 16px;
    width: 90%;
  }
  .qr-style{
    margin-bottom: -30px;
  }
  .coin-display{
    font-size: 38px;
  }
  .earn-modal-content {
    height: 100%;
    max-height: calc(100vh - 90px);
  }

  .slider-text {
    font-size: 12px;
  }
}

@media (max-height: 640px) {
  .earn-modal-content {
    height: 100%;
    margin-bottom: 50px;
    max-height: calc(100vh - 90px);
  }
}


@media (max-height: 600px) {





  .background-image {
    position: absolute;
    top: -28px;
    left: -25px;
    width: 92%;
    height: auto;
    z-index: 0;
  }

  .animation-container {
    width: 280px;  /* عرض مورد نظر برای انیمیشن */
    height: 260px; /* ارتفاع مورد نظر برای انیمیشن */
    margin: 0 auto; /* برای قرار دادن در مرکز صفحه */
  }


  .earn-modal-top-image {
    width: 140px;
    height: 100px;
  }

  .invited-users-container {
    max-height: 220px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .boost-text-header {
    margin-left: 15px;
    font-size: 20px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
  .header-left, .header-right {
    margin: 0 5px;
  }
  .icon, .icon2 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    font-size: 14px;
  }
  .icon1{
    margin-right: 5px;
  }
  .modal-button {
    padding: 5px;
    font-size: 14px;
  }
  .level-display {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .progress-bar, .progress {
    height: 8px;
  }
  .ncc-container {
    width: 96%;
    height: 1200px;
    padding: 10px;
  }
  .box-test1, .box-test2, .box-test3 {
    font-size: 12px;
  }
  .Army-container {
    width: 68%;
  }
  .Army{
    width: 205px;
    height: auto;
    display: flex;
    justify-content: center;
  }
  .hamster-container img {
    width: 50px;
    height: 50px;
  }
  .charge .icon, .boost .icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .coin-display {
    font-size: 36px;
    margin: 10px 0;
  }
  .coin-display .coin-icon {
    width: 48px;
    height: 48px;
    margin-right: 5px;
  }
  .info-container, .info-box {
    padding: 5px 10px;
    font-size: 14px;
  }
  .value {
    font-size: 12px;
  }
  .actions-container {
    padding: 5px;
  }
  .charge, .boost {
    font-size: 14px;
    padding: 5px;
    margin-right: 20px;
    margin-left: 12px;
  }
  .click {
    font-size: 24px;
  }
  .modal {
    height: 300px;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-content-label {
    margin-top: 20px;
  }
  .close {
    width: 24px;
    height: 24px;
  }
  .boost-modal {
    padding: 10px;
  }
  .boost-modal-content {
    padding: 10px;
    margin-left: -20px;
    margin-top: -10px;
  }
  .boost-option {
    padding: 1px;
    font-size: 12px;
  }
  .boost-option img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .boost-option span {
    font-size: 12px;
  }
  .boost-price {
    font-size: 12px;
  }
  .boost-info {
    padding: 5px;
  }
  .boost-info img {
    width: 20px;
    height: 20px;
  }
  .boost-text {
    font-size: 14px;
  }
  .cooldown-timer, .cooldown-timer-modal, .boost-availability {
    font-size: 12px;
  }
  .countdown-timer {
    font-size: 12px;
    margin-left: 10px;
  }
  .confirm-modal {
    padding: 10px;
  }
  .confirm-modal-content {
    padding: 10px;
  }

  .close-modal1 {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }
  .energy-modal {
    padding: 10px;
  }
  .free-style {
    font-size: 24px;
  }
  .task-icon{
    width: 20px;
    height: 20px;
  }
  .task-daily-list {
    margin: 5px;
    padding: 2px;
  }

  .task-item, .task-info, .task-title, .task-reward, .task-status {
    font-size: 12px;

  }
  .telegram-modal, .telegram-modal-content, .telegram-icon, .join-button, .check-button {
    font-size: 16px;
  }
  .reward-info, .reward-icon {
    padding: 5px;
    font-size: 12px;
  }
  .join-tg-group, .join-tg-group2, .join-instagram-group, .join-x-group {
    padding: 5px;
    font-size: 24px;
  }
  .telegram-price, .telegram-modal-coin {
    font-size: 24px;
    margin-left: 5px;
  }
  .error-modal, .error-modal-content {
    padding: 10px;
  }
  .error-text {
    font-size: 16px;
  }
  .error-ok-button {
    padding: 10px;
    font-size: 16px;
  }
  .success-modal, .success-modal-content {
    padding: 10px;
  }
  .success-modal-content button {
    padding: 10px;
    font-size: 16px;
  }
  .telegram-modal2, .telegram-modal-content2, .telegram-icon2, .join-button2, .reward-info2, .reward-icon2, .telegram-price2, .telegram-modal-coin2 {
    font-size: 24px;
  }
  .instagram-modal, .instagram-modal-content, .instagram-icon, .instagram-price, .instagram-modal-coin {
    font-size: 24px;
  }
  .x-modal, .x-modal-content, .x-icon, .x-price, .x-modal-coin {
    font-size: 24px;
  }
  .daily-reward-modal, .daily-reward-modal-content, .daily-reward-grid, .reward-item, .claim-button, .image-container, .mine-image, .wallet-input, .tabs, .tab-content, .alert-modal, .alert-modal-content, .alert-button, .friend-modal, .friend-modal-content, .bonus-item, .modal-buttons, .invite-button, .other-button, .friend-table, .friend-list-container, .friend-list-header, .no-friends-text, .friend-table td, .friend-table .friend-row, .friend-table .friend-row img, .friend-table .friend-row .friend-info, .friend-table .friend-row .friend-info span, .friend-table .friend-row .friend-rewards, .friend-table .friend-row .friend-rewards span, .friend-table .friend-row .friend-rewards img, .show-table-button, .invited-users-table, .coin-icon, .image-container2, .image-container2 img, .download-link, .image-container, .generated-image, .dail-combo-text, .daily-influence, .dail-combo-image, .daily-combo-box, .daily-combo-dots, .dot, .daily-combo-reward, #hashtag, .qr-container, .qr-container img, .qr-container p, .refresh, .refresh-btn, .menu-item, .menu-icon, .daily-reward, .task-list, .task-list .task-item, .task-list .task-item h3, .task-list .task-item p, .settings, .settings input, .preloader, .preloader-content, .pre-con-header, .pre-con-text, .pre-con-text1, .loader, .social-icons, .social-icons img, .coin {
    font-size: 12px;
    margin-top: 2px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-menu {
    padding: 5px 0;
  }
  .footer{
    padding: 0;
  }
  .coming-soon {
    margin-top: -15px ;
    width: 400px;
    height: auto;
  }
  .register-button{
    padding: 1.2rem 1rem;
    font-size: 16px;
    width: 90%;
  }
  .qr-style{
    margin-bottom: -30px;
  }
  .coin-display{
    font-size: 38px;
  }
  .earn-modal-content {
    height: auto;
    max-height: calc(100vh - 90px);
  }

  .slider-text {
    font-size: 12px;
  }

  .earn-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    transition: opacity 0.3s ease;
    overflow-y: auto;
  }
}







@media (max-height: 500px) {

  .golden-glow {
    top: -25px;
  }

  .spin-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #FFD700;
    margin-top: -20px;
    top: -30px;
    color: black;
    border: 2px solid #FFD700;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease;
  }

  .modal-content1 {
    background-color: rgba(0, 0, 0, 0.46);
    padding: 20px;
    border-radius: 10px;
    left: 7px;
    max-width: 500px;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 15px;
    position: relative;
    box-shadow: 0px 0px 20px rgb(255, 153, 0);
    border: 3px solid rgba(253, 197, 1, 0.92);
    margin-left: -15px;
    width: 100%;
    top: -20px;
    height: 450px;
  }

  .wheel,
  .label,
  .prize {
    transform: scale(0.7);
    transform-origin: center;
  }

  .wheel-container
  {
    transform: scale(0.7);
    transform-origin: center;
    top: -50px;
    left: 0;
  }

  .wheel-center{
    transform: scale(0.7);
    transform-origin: center;
    top: 42%;
    left: 42%;
    width: 60px;
    height: 60px;
  }
  .Congratulations {
    display: block;
    margin-top: -70px;
    top: -60px;
  }





  .earn-modal-top-image {
    width: 140px;
    height: 100px;
  }

  .invited-users-container {
    max-height: 220px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .boost-text-header {
    margin-left: 15px;
    font-size: 20px;
  }
  .header {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
  .header-left, .header-right {
    margin: 0 5px;
  }
  .icon, .icon2 {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    font-size: 14px;
  }
  .icon1{
    margin-right: 5px;
  }
  .modal-button {
    padding: 5px;
    font-size: 14px;
  }
  .level-display {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .progress-bar, .progress {
    height: 8px;
  }
  .ncc-container {
    width: 96%;
    height: 1200px;
    padding: 10px;
  }
  .box-test1, .box-test2, .box-test3 {
    font-size: 12px;
  }
  .Army-container {
    width: 65%;
    padding: 0;

  }
  .Army{
    width: 150px;
    height: auto;
    display: flex;
    left: 20px;
    top: -8px;
    justify-content: center;
  }
  .hamster-container img {
    width: 50px;
    height: 50px;
  }
  .charge .icon, .boost .icon {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .coin-display {
    font-size: 36px;
    margin: 10px 0;
  }
  .coin-display .coin-icon {
    width: 48px;
    height: 48px;
    margin-right: 5px;
  }
  .info-container, .info-box {
    padding: 5px 10px;
    font-size: 14px;
  }
  .value {
    font-size: 12px;
  }
  .actions-container {
    display: flex;
    padding: 5px;
    margin-bottom: 125px;
  }
  .charge, .boost {
    font-size: 14px;
    padding: 5px;
    margin-right: 20px;
    margin-left: 12px;
  }
  .click {
    font-size: 24px;
  }
  .modal {
    height: 300px;
  }
  .modal-content {
    padding: 10px;
  }
  .modal-content-label {
    margin-top: 20px;
  }
  .close {
    width: 24px;
    height: 24px;
  }
  .boost-modal {
    padding: 10px;
  }
  .boost-modal-content {
    padding: 10px;
    margin-left: -20px;
    margin-top: -10px;
  }
  .boost-option {
    padding: 1px;
    font-size: 12px;
  }
  .boost-option img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .boost-option span {
    font-size: 12px;
  }
  .boost-price {
    font-size: 12px;
  }
  .boost-info {
    padding: 5px;
  }
  .boost-info img {
    width: 20px;
    height: 20px;
  }
  .boost-text {
    font-size: 14px;
  }
  .cooldown-timer, .cooldown-timer-modal, .boost-availability {
    font-size: 12px;
  }
  .countdown-timer {
    font-size: 12px;
    margin-left: 10px;
  }
  .confirm-modal {
    padding: 10px;
  }
  .confirm-modal-content {
    padding: 10px;
  }

  .close-modal1 {
    width: 24px;
    height: 24px;
    font-size: 18px;
  }
  .energy-modal {
    padding: 10px;
  }
  .free-style {
    font-size: 24px;
  }
  .task-icon{
    width: 20px;
    height: 20px;
  }
  .task-daily-list {
    margin: 5px;
    padding: 2px;
  }

  .task-item, .task-info, .task-title, .task-reward, .task-status {
    font-size: 12px;

  }
  .telegram-modal, .telegram-modal-content, .telegram-icon, .join-button, .check-button {
    font-size: 16px;
  }
  .reward-info, .reward-icon {
    padding: 5px;
    font-size: 12px;
  }
  .join-tg-group, .join-tg-group2, .join-instagram-group, .join-x-group {
    padding: 5px;
    font-size: 24px;
  }
  .telegram-price, .telegram-modal-coin {
    font-size: 24px;
    margin-left: 5px;
  }
  .error-modal, .error-modal-content {
    padding: 10px;
  }
  .error-text {
    font-size: 16px;
  }
  .error-ok-button {
    padding: 10px;
    font-size: 16px;
  }
  .success-modal, .success-modal-content {
    padding: 10px;
  }
  .success-modal-content button {
    padding: 10px;
    font-size: 16px;
  }
  .telegram-modal2, .telegram-modal-content2, .telegram-icon2, .join-button2, .reward-info2, .reward-icon2, .telegram-price2, .telegram-modal-coin2 {
    font-size: 24px;
  }
  .instagram-modal, .instagram-modal-content, .instagram-icon, .instagram-price, .instagram-modal-coin {
    font-size: 24px;
  }
  .x-modal, .x-modal-content, .x-icon, .x-price, .x-modal-coin {
    font-size: 24px;
  }
  .daily-reward-modal, .daily-reward-modal-content, .daily-reward-grid, .reward-item, .claim-button, .image-container, .mine-image, .wallet-input, .tabs, .tab-content, .alert-modal, .alert-modal-content, .alert-button, .friend-modal, .friend-modal-content, .bonus-item, .modal-buttons, .invite-button, .other-button, .friend-table, .friend-list-container, .friend-list-header, .no-friends-text, .friend-table td, .friend-table .friend-row, .friend-table .friend-row img, .friend-table .friend-row .friend-info, .friend-table .friend-row .friend-info span, .friend-table .friend-row .friend-rewards, .friend-table .friend-row .friend-rewards span, .friend-table .friend-row .friend-rewards img, .show-table-button, .invited-users-table, .coin-icon, .image-container2, .image-container2 img, .download-link, .image-container, .generated-image, .dail-combo-text, .daily-influence, .dail-combo-image, .daily-combo-box, .daily-combo-dots, .dot, .daily-combo-reward, #hashtag, .qr-container, .qr-container img, .qr-container p, .refresh, .refresh-btn, .menu-item, .menu-icon, .daily-reward, .task-list, .task-list .task-item, .task-list .task-item h3, .task-list .task-item p, .settings, .settings input, .preloader, .preloader-content, .pre-con-header, .pre-con-text, .pre-con-text1, .loader, .social-icons, .social-icons img, .coin {
    font-size: 12px;
    margin-top: 2px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mobile-menu {
    padding: 0 0;
  }
  .footer{
    padding: 0;
  }
  .coming-soon {
    margin-top: -20px;
    width: 345px;
    height: auto;
    left: 10px;
  }
  .register-button{
    padding: 1.2rem 1rem;
    font-size: 16px;
    width: 90%;
  }
  .qr-style{
    margin-bottom: -30px;
  }
  .coin-display{
    font-size: 38px;
  }
  .earn-modal-content {
    height: auto;
    max-height: calc(100vh - 90px);
  }

  .slider-text {
    font-size: 12px;
  }

  .earn-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    transition: opacity 0.3s ease;
    overflow-y: auto;
  }

  .background-image {
    position: absolute;
    top: -25px;
    left: 10px;
    width: 92%;
    height: auto;
    z-index: 0;
  }

  .animation-container {
    width: 200px;  /* عرض مورد نظر برای انیمیشن */
    height: 180px; /* ارتفاع مورد نظر برای انیمیشن */
    margin: 0 auto; /* برای قرار دادن در مرکز صفحه */
  }

  .label-class {
    display: none;
    margin-top: 0;
    color: rgba(255, 255, 255, 0.4);
    font-family: Roboto;
    font-size: 14px;
  }
}
























.red-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}























